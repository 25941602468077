import { Tag } from "antd";
import { startCase } from "lodash";
import moment from "moment";

export const is_production = process.env.REACT_APP_PRODUCTION === "true";
// export const is_dev = location.origin === "https://ects-dev.vertace.org";

export const ProjectName = "ECS";
export const colorCode = [
  "linear-gradient(315deg, rgb(204 131 217 / 84%) 0%, rgb(149 38 203) 74%)",
  "linear-gradient(321deg, rgb(3 219 241 / 67%) 0%, rgb(63 81 181 / 92%) 74%)",
  "linear-gradient(to right, #f7971e, #ffd200)",
  "linear-gradient(90deg, hsla(358, 78%, 62%, 1) 12%, hsla(42, 87%, 85%, 1) 100%)",
  "linear-gradient(234deg,   #43e595  0%,#3db2b7  82%)",
];
export const success = "Success";
export const tenure_period_type = [
  {
    id: "days",
    name: "Days",
  },
  {
    id: "months",
    name: "Months",
  },
];
export const interest_calculation_type = [
  {
    id: "simple",
    name: "Simple",
  },
  {
    id: "compound",
    name: "Compound",
  },
];
export const allowed_for = [
  {
    id: "employee",
    name: "Employee",
  },
  {
    id: "member",
    name: "Member",
  },
];

export const interest_payment_type = [
  {
    id: "monthly",
    name: "Monthly",
  },
  {
    id: "quarterly",
    name: "Quarterly",
  },
  {
    id: "half_yearly",
    name: "Half Yearly",
  },
  {
    id: "annually",
    name: "Annually",
  },
  {
    id: "on_maturity",
    name: "ON Maturity",
  },
];

export const interest_payout_frequency_for_sb = [
  {
    id: "monthly",
    name: "Monthly",
  },
  {
    id: "quarterly",
    name: "Quarterly",
  },
  {
    id: "half_yearly",
    name: "Half Yearly",
  },
  {
    id: "annually",
    name: "Annually",
  },
];
export const deposit_types = [
  {
    id: "Fixed",
    name: "fixed",
  },
  {
    id: "Recurring",
    name: "recurring",
  },
];

export const deposit_closure_types = [
  {
    id: "any_time",
    name: "Any Time",
  },
  {
    id: "on_maturity",
    name: "On Maturity",
  },
  {
    id: "on_retirement",
    name: "On Retirement",
  },
  {
    id: "on_loan_closure",
    name: "On Loan Closure",
  },
  {
    id: "non_refundable",
    name: "Non-Refundable",
  },
];

export const deposit_ci_frequency_type = [
  {
    id: "monthly",
    name: "Monthly",
  },
  {
    id: "quarterly",
    name: "Quarterly",
  },
  {
    id: "half_yearly",
    name: "Half Yearly",
  },
  {
    id: "annually",
    name: "Annually",
  },
  {
    id: "on_maturity",
    name: "ON Maturity",
  },
];

export const account_type = [
  {
    value: "savings",
    label: "Savings",
  },
  {
    value: "current",
    label: "Current",
  },
];

export const nominee_relations = [
  {
    id: "Father",
    name: "Father",
  },
  {
    id: "Mother",
    name: "Mother",
  },
  {
    id: "Spouse",
    name: "Spouse",
  },
  {
    id: "Son",
    name: "Son",
  },
  {
    id: "Daughter",
    name: "Daughter",
  },
  {
    id: "Brother",
    name: "Brother",
  },
  {
    id: "Sister",
    name: "Sister",
  },
  {
    id: "Other",
    name: "Other",
  },
];

export const formatDate = (value_date) => {
  const dateObj = new Date(value_date);
  const isoString = dateObj.toISOString();
  return isoString.toString();
};

export const formattedDate = (date) => {
  let temp = moment(date, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm:ss.SS");
  return temp;
};

export const relation_options = [
  { id: "Mother", name: "Mother" },
  { id: "Father", name: "Father" },
  { id: "Wife", name: "Wife" },
  { id: "Son", name: "Son" },
  { id: "Daughter", name: "Daughter" },
  { id: "Brother", name: "Brother" },
];

export const overdue_filter = [
  {
    label: "Overdue 30 Days",
    value: "D_30",
  },
  {
    label: "Overdue 60 Days",
    value: "D_60",
  },
  {
    label: "Overdue 90 Days",
    value: "D_90",
  },
  {
    label: "3-6 Months Overdue",
    value: "M_6",
  },
  {
    label: "6-12 Months Overdue",
    value: "M_12",
  },
  {
    label: "1-3 Years overdue",
    value: "Y_3",
  },
  {
    label: "3-5 Years overdue",
    value: "Y_5",
  },
  {
    label: "5 Years and Above overdue",
    value: "ABOVE",
  },
];

export function disableFutureDate(current) {
  return current && current.valueOf() > Date.now();
}

export const member_type = [
  {
    id: "a_class",
    name: "A Class",
  },
  {
    id: "b_class",
    name: "B Class",
  },
  {
    id: "others",
    name: "Associate Member",
  },
];
export const member_type_two = [
  {
    value: "a_class",
    label: "A Class Associate Member",
  },
  {
    value: "b_class",
    label: "Associate Member",
  },
];
export const fd_account_type = [
  {
    id: "individual",
    name: "Individual",
  },
  {
    id: "joint",
    name: "Joint",
  },
  {
    id: "either_or_survivor",
    name: "Either or Survivor",
  },
];
export const TenureDaysType = [
  {
    label: "Days",
    value: "days",
  },
  {
    label: "Months",
    value: "months",
  },
  {
    label: "Years",
    value: "years",
  },
];

export const TenureDaysSelectType = [
  {
    label: "Days",
    value: "days",
  },
];

export const TenureMonthsSelectType = [
  {
    label: "Months",
    value: "months",
  },
];

export const TenureYearsSelectType = [
  {
    label: "Years",
    value: "years",
  },
];

export const religion = [
  {
    id: "hindu",
    name: "Hindu",
  },
  {
    id: "christian",
    name: "Christian",
  },
  {
    id: "muslim",
    name: "Muslim",
  },
  {
    id: "jain",
    name: "Jain",
  },
  {
    id: "buddhist",
    name: "buddhist",
  },
  {
    id: "sikhs",
    name: "Sikhs",
  },
  {
    id: "others",
    name: "Others",
  },
];
export const member_community = [
  {
    id: "bc",
    name: "BC",
  },
  {
    id: "mbc",
    name: "MBC",
  },
  {
    id: "sc",
    name: "SC",
  },
  {
    id: "st",
    name: "ST",
  },
  {
    id: "oc",
    name: "OC",
  },
  {
    id: "others",
    name: "Others",
  },
];

export const formatStatus = (value) => {
  switch (value) {
    case "active":
      return <Tag className="status-tag status-active"> Active</Tag>;
    case "Success":
    case "Sent":
      return <Tag className="status-tag status-active"> Success</Tag>;
    case "Approved":
      return <Tag className="status-tag status-active"> Approved</Tag>;
    case "Failure":
      return <Tag className="status-tag status-closed">Failure</Tag>;
    case "Rejected":
      return <Tag className="status-tag status-closed">Rejected</Tag>;
    case "closed":
      return <Tag className="status-tag status-closed">Closed</Tag>;
    case "maturity":
      return <Tag className="status-tag status-maturity">Maturity</Tag>;
    case "matured":
      return <Tag className="status-tag status-maturity">Matured</Tag>;
    case "Pending":
    case "on_queue":
      return <Tag className="status-tag status-pending">Pending</Tag>;
    case "Reverted":
    case "failed":
      return <Tag className="status-tag status-revert">{startCase(value)}</Tag>;
    case "duplicate":
      return <Tag className="status-tag status-revert">Duplicate</Tag>;
    case "processed_with_error":
      return <Tag className="status-tag status-revert">{startCase(value)}</Tag>;
    case "processing":
      return (
        <Tag className="status-tag status-pending">{startCase(value)}</Tag>
      );
    case "processed":
      return <Tag className="status-tag status-active">Processed</Tag>;
    case "od_notice":
      return <Tag className="status-tag status-revert">OD</Tag>;
    case "arc":
      return <Tag className="status-tag status-revert">ARC</Tag>;
    case "decree":
      return <Tag className="status-tag status-revert">Decree</Tag>;
    case "ep":
      return <Tag className="status-tag status-revert">EP</Tag>;
    case "skip":
      return <Tag className="status-tag status-pending">Skip</Tag>;
    case "Demand Already Processed":
      return (
        <Tag className="status-tag status-revert">Demand Already Processed</Tag>
      );
    case "No Pay Officer Dueby Available":
      return (
        <Tag className="status-tag status-revert">
          No Pay Officer Dueby Available
        </Tag>
      );
    default:
      return value ? startCase(value) : "-";
  }
};

export const status_options = [
  {
    id: "active",
    name: "Active",
  },
  {
    id: "matured",
    name: "Matured",
  },
  {
    id: "closed",
    name: "Closed",
  },
];

export const PincodeRegex = new RegExp("^6[0-9]{5}$");

export const ifscCode = new RegExp("/^[a-zA-Z0-9]{11}$/");
// export const mobileNumberRegex = new RegExp("^(?!.*(.)\\1{5,})[6-9][0-9]{9}$");
export const mobileNumberRegex = new RegExp("^[6-9][0-9]{9}$");

export const aadharRegex = new RegExp("^[0-9]{12}$");

export const accountNumberRegex = new RegExp("^[0-9]{9,18}$");

export const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;

export const emailRegex = new RegExp(
  "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"
);

export const index_with_letter = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export const index_with_quadrant = [
  "I",
  "II",
  "III",
  "IV",
  "V",
  "VI",
  "VII",
  "VIII",
  "IX",
  "X",
  "XI",
  "XII",
  "XIII",
  "XIV",
  "XV",
  "XVI",
  "XVII",
  "XVIII",
  "XIX",
  "XX",
  "XXI",
  "XXII",
  "XXIII",
  "XXIV",
  "XXV",
  "XXVI",
  "XXVII",
  "XXVIII",
  "XXIX",
  "XXX",
  "XXXI",
  "XXXII",
  "XXXIII",
  "XXXIV",
  "XXXV",
  "XXXVI",
  "XXXVII",
  "XXXVIII",
  "XXXIX",
  "XL",
  "XLI",
  "XLII",
  "XLIII",
  "XLIV",
  "XLV",
  "XLVI",
  "XLVII",
  "XLVIII",
  "XLIX",
  "L",
];

function groupBy(array, key) {
  return array?.reduce((result, item) => {
    (result[item[key]] = result[item[key]] || []).push(item);
    return result;
  }, {});
}

export const previous_year_and_current_year_combine = (
  current_year,
  previous_year
) => {
  // Grouping both arrays by "group" attribute
  let groupedPreviousYear = groupBy(previous_year, "group");
  let groupedCurrentYear = groupBy(current_year, "group");

  // Merging both arrays based on "group"
  let mergedData = Object.keys(groupedPreviousYear)?.reduce((result, key) => {
    if (groupedCurrentYear[key]) {
      let mergedGroup = groupedPreviousYear[key]?.map((item, index) => {
        return {
          ...item,
          gl_account: item.gl_account?.map((gl, idx) => {
            let cur_item = groupedCurrentYear?.[key]?.[index]?.gl_account?.find(
              (a) => a.gl_account_id === gl.gl_account_id
            );

            return {
              ...gl,
              previous_year_current_balance: gl?.current_balance,
              current_balance: cur_item?.current_balance,
            };
          }),
        };
      });
      result?.push(...mergedGroup);
    }
    return result;
  }, []);
  return mergedData;
};
export const un_grouped_values_combine = () => {};

export function get_last_day_of_month(x, y) {
  const dateX = moment(x);
  const dateY = moment(y);
  if (dateX.month() === dateY.month() && dateX.year() === dateY.year()) {
    // return moment(y).format("DD/MM/YYYY"); // Return y if months and years are equal
    // return moment(dateValue).clone().subtract(1, 'year').endOf('month').format("DD/MM/YYYY");
    return moment(y)
      .clone()
      .subtract(1, "month")
      .endOf("month")
      .format("DD/MM/YYYY");
  } else {
    // Return the last day of the same month from the previous year of y
    // return moment(y).subtract('year').endOf('month').format("DD/MM/YYYY");
    return moment(y)
      .clone()
      .subtract(1, "year")
      .subtract(1, "month")
      .endOf("month")
      .format("DD/MM/YYYY");
  }
}

export function get_last_day(date) {
  let month = moment(date).format("MM");
  let year = moment(date).format("YYYY");
  let next_month_date = new Date(year, month, 1);
  next_month_date.setDate(0);
  return moment(next_month_date);
}
export function get_first_day(date) {
  let monthFormatted = moment(date).format("MM");
  let yearFormatted = moment(date).format("YYYY");

  // Format the date to DD/MM/YYYY format
  let formattedDate = `${1}/${monthFormatted}/${yearFormatted}`;

  return formattedDate;
}

export function get_current_of_month(dateValue) {
  return moment(dateValue)
    .clone()
    .subtract("year")
    .endOf("month")
    .format("DD/MM/YYYY");
}

export const deposit_unwanted_columns = [
  "deposit_product",
  "id",
  "current_month_balance",
];
export const surety_loan_unwanted_columns = [
  "demand_validity",
  "id",
  "loan_number",
  "recorded_accounting_day",
  "recorded_date",
  "surety_loan_id",
];

export const change_month_wise = (gl_statement) => {
  console.log("gl_statement: ", gl_statement);

  if (gl_statement?.length !== 0) {
    let response = [];
    for (let i = 0; i < gl_statement?.length; i++) {
      const gl = gl_statement[i];
      // let line_items = gl?.line_items;

      // Assuming `gl` is defined and contains `line_items`
      let line_items = gl?.line_items;
      let sortedLineItems;
      // Check if line_items exists and is an array before sorting
      if (Array.isArray(line_items)) {
        // Create a shallow copy of the array to avoid mutation
        sortedLineItems = [...line_items].sort((a, b) => {
          // Convert DD-MM-YYYY to YYYY-MM-DD for proper date sorting
          const parseDate = (dateString) => {
            const [day, month, year] = dateString.split("-");
            return new Date(`${year}-${month}-${day}`);
          };

          let dateA = parseDate(a?.date);
          let dateB = parseDate(b?.date);

          // Ensure dates are valid before comparing
          if (!dateA || !dateB) return 0;

          // Sort in ascending order (earliest first)
          return dateA - dateB; // Ascending order
        });
      }

      const groupedByMonthYear = sortedLineItems?.reduce((acc, item) => {
        const [day, month, year] = item?.date?.split("-");
        const key = `${month}-${year}`;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
        return acc;
      }, {});
      console.log("groupedByMonthYear", groupedByMonthYear);

      let op_balance = gl?.opening_balance;
      let gl_line_items = [];
      let previousMonthTotals = null; // Initialize previous month totals

      // Iterate over months in groupedByMonthYear
      Object.entries(groupedByMonthYear)?.forEach(([monthYear, lineItems]) => {
        let monthly_items = [];
        let monthTotals = {
          balance: 0,
          credit: 0,
          debit: 0,
          previous: { ...previousMonthTotals }, // Include previous month's totals
        };

        sortedLineItems?.forEach((item) => {
          // Calculate GL balance
          if (item.credit !== 0) {
            op_balance = calculate_gl(gl?.type, item?.credit, true, op_balance);
          }

          if (item.debit !== 0) {
            op_balance = calculate_gl(gl?.type, item?.debit, false, op_balance);
          }

          monthTotals.balance += item?.balance;
          monthTotals.credit += item?.credit;
          monthTotals.debit += item?.debit;

          monthly_items.push({ ...item, balance: op_balance });
        });

        previousMonthTotals = { ...monthTotals };

        if (monthly_items.length) {
          let obj = {
            for: monthYear,
            transaction: monthly_items,
            totals: { ...monthTotals },
          };

          gl_line_items.push(obj);
        }
      });

      response.push({ ...gl, line_items: gl_line_items });
    }

    function calculate_gl(gl_type, amount, is_credit, op_balance) {
      let amt = amount;
      if (gl_type === "OperatingRevenues") {
        debugger;
      }
      switch (gl_type) {
        case "Assets":
        case "OperatingExpenses":
        case "OperatingRevenues":
        case "NonOperatingExpensesLosses":
          amt *= -1; //Increases on Debit
          break;
      }

      if (is_credit) {
        op_balance += amt;
      } else {
        op_balance -= amt;
      }

      return op_balance;
    }
    console.log("response: ", response);
    return response;
  }
};
export const re_change = (values) => {
  if (values?.length) {
    // Iterate over each item in the values array
    values?.forEach((item) => {
      let previousMonthTotals = null; // Initialize previous month totals

      // Iterate over each line item in the line_items array
      item?.line_items?.forEach((monthItems) => {
        // Initialize totals for the current month
        let totals = {
          balance: 0,
          credit: 0,
          debit: 0,
          previous: {
            balance: 0,
            credit: 0,
            debit: 0,
          },
        };

        // Iterate over line items of the current month and sum up balances, credits, and debits
        monthItems?.forEach((lineItem) => {
          totals.balance += lineItem.balance;
          totals.credit += lineItem.credit;
          totals.debit += lineItem.debit;
        });

        // Add the totals from the previous month if it's not the first month
        if (previousMonthTotals !== null) {
          totals.previous.balance = previousMonthTotals.balance;
          totals.previous.credit = previousMonthTotals.credit;
          totals.previous.debit = previousMonthTotals.debit;
        }

        // Update the totals for the current month
        monthItems.totals = totals;

        // Set the totals of the current month as the previous month's totals for the next iteration
        previousMonthTotals = { ...totals }; // Make a copy to prevent reference sharing
      });
    });

    return values;
  }
};

export const monthAndYearDifference = (previousDate, currentDate) => {
  // Parse the dates
  const previous = new Date(previousDate);
  const current = new Date(currentDate);

  // Calculate the difference in months
  const monthDifference =
    (current.getFullYear() - previous.getFullYear()) * 12 +
    (current.getMonth() - previous.getMonth());

  // Calculate the difference in years
  const yearDifference = current.getFullYear() - previous.getFullYear();

  return { monthDifference, yearDifference };
};

export const paginationOptions = {
  showSizeChanger: true,
  pageSizeOptions: ["10", "20", "50", "100"], // Customize the page size options
  defaultPageSize: 10, // Set the default page size
  showTotal: (total, range) => `Total ${total} items`,
};

export const matched_columns_based_amount = (columns, record, code) => {
  let matchedTransactions = [];
  columns
    ?.filter((col) => col?.name === code)
    ?.forEach((ledgerColumn) => {
      ledgerColumn?.columns?.forEach((column) => {
        let matchedTransaction = record?.filter((record) => {
          return record?.gl_account_id === column?.gl_id;
        });

        if (matchedTransaction) {
          matchedTransactions.push({
            column: column?.name,
            ...matchedTransaction?.[0],
          });
        }
      });
    });

  return matchedTransactions;
};

export const get_financial_year = (accounting_date, current_date) => {
  const currentDate = moment(accounting_date);

  let startFinancialYear, endFinancialYear;
  if (currentDate.month() < 3) {
    startFinancialYear = moment(accounting_date)
      .subtract(1, "years")
      .startOf("year");
    endFinancialYear = moment().endOf("year");
  } else {
    startFinancialYear = moment().startOf("year");
    endFinancialYear = moment().add(1, "years").endOf("year");
  }
  const startDate = startFinancialYear.format("DD/MM/YYYY");

  return `${startDate} to ${moment(current_date).format("DD/MM/YYYY")}`;
};

export const queryStringSet = (key, value) => {
  const queryParams = new URLSearchParams();
  queryParams.set(key, value);
  return queryParams.toString();
};

export const queryStringRetrieve = (key, value) => {
  const queryParams = new URLSearchParams(value);
  return queryParams.get(key);
};
export const transaction_mode_options = [
  {
    id: "Cash",
    name: "Cash",
    is_payment: true,
    is_receipt: true,
  },
  {
    id: "NEFT_RTGS",
    name: "NEFT/RTGS",
    is_payment: true,
    is_receipt: true,
  },
  {
    id: "UPI",
    name: "UPI ",
    is_payment: false,
    is_receipt: true,
  },
  {
    id: "Individual",
    name: "Individual Deposit",
    is_payment: false,
    is_receipt: true,
  },
  {
    id: "Cheque",
    name: "Cheque ",
    is_payment: true,
    is_receipt: true,
  },
  {
    id: "DD",
    name: "DD ",
    is_payment: true,
    is_receipt: true,
  },
  {
    id: "ECS",
    name: "ECS ",
    is_payment: true,
    is_receipt: true,
  },
];

export const general_settings_types = [
  {
    id: "working_days",
    name: "Working Days",
  },
  {
    id: "calender_days",
    name: "Calender Days",
  },
];

export const status_options_society = [
  {
    id: "back_log_entry",
    name: "Backlog Entry",
  },
  {
    id: "live",
    name: "Live",
  },
  {
    id: "not_started",
    name: "Not Started",
  },
  {
    id: "test_credential_verified",
    name: "Test Credential Verified",
  },
  {
    id: "test_credentials_given",
    name: "Test credentials given",
  },
  {
    id: "uploaded_with_error",
    name: "Uploaded With Error",
  },
  {
    id: "validated",
    name: "Validated",
  },
];

export const reason_to_revert = [
  {
    id: "system_mistake",
    name: "System Mistake",
  },
  {
    id: "my_mistake",
    name: "Society Data Error",
  },
  {
    id: "data_missing",
    name: "Data Missing",
  },
];
