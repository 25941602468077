import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  HStack,
  SimpleGrid,
  Text,
  useBreakpointValue,
} from "native-base";
import VForm from "@views/components/ui/antd_form";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NoOfSocieties from "@assets/icons/number_of_societies.png";
import Validated2 from "@assets/images/validate.png";
import precessing from "@assets/images/back_log_entry.png";
import NotStarted from "@assets/images/not-started-new.png";
import UploadIcon from "@assets/images/upload.png";
import Credential from "@assets/images/Credential_migration_db.png";
import Live from "@assets/images/Live_migration_db_icon.png";

import { dynamicRequest, useDynamicSelector } from "@services/redux";
import * as XLSX from "xlsx";
import { Checkbox, Table } from "antd";
import {
  get_data_migration_dashboard,
  get_migration_dashboard_district_wise_list_query,
  get_migration_districts,
} from "../../../services/redux/slices/dashboard/graphql";
import DataMigrationDashboardLoading from "./data_migration_dashboard_loading";
import DashboardCard from "./dasboard_card";
import { handle_excel_download } from "helpers/functions";
import moment from "moment";
// import useParamsVelocityDashboardHook from "@views/components/hooks/use_search_params_velocity";

const MigrationDashboard = ({ type, active_key }) => {
  // const { form_date } = useParamsVelocityDashboardHook();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const roles = localStorage.getItem("roles");
  const [district_ids, set_district_ids] = useState([]);
  const [from_date, set_from_date] = useState(null);
  const [to_date, set_to_date] = useState(moment(new Date()));
  const [show_percentage, set_show_percentage] = useState(false);
  const [is_show_aging, set_is_show_aging] = useState(false);

  let role = "";
  const data = useDynamicSelector("getDataMigrationDashboardMigration");

  const { items: get_districts } = useDynamicSelector("get_districts");

  const { items: districts, loading: district_loading } =
    useDynamicSelector("getDistricts");

  const {
    items: district_wise_count_list,
    loading: district_wise_count_list_loading,
  } = useDynamicSelector("getReport");

  useEffect(() => {
    if (to_date) {
      const new_from_date = moment(to_date).subtract(7, "days");
      set_from_date(moment(new Date(new_from_date)));
    }
  }, [to_date]);

  useEffect(() => {
    get_migration_districts_list();
    get_migration_dashboard_district_wise_list();
  }, []);

  useEffect(() => {
    if (from_date && to_date && district_ids && district_ids.length > 0) {
      get_data_migration_dashboard_query();
      return;
    }
    if (active_key != "live") {
      get_data_migration_dashboard_query();
    }
  }, [district_ids, from_date, to_date, active_key]);

  const get_data_migration_dashboard_query = () => {
    let keys = [{ key: "getDataMigrationDashboardMigration", loading: true }];
    let variable = {
      district_ids: district_ids,
      from_date: type === "velocity" ? from_date : null,
      to_date: type === "velocity" ? to_date : null,
    };
    dispatch(dynamicRequest(keys, get_data_migration_dashboard, variable));
  };

  const get_migration_districts_list = () => {
    let keys = [{ key: "getDistricts", loading: true }];
    let variable = {};
    dispatch(dynamicRequest(keys, get_migration_districts, variable));
  };

  const get_migration_dashboard_district_wise_list = (val) => {
    let keys = [{ key: "getReport", loading: true }];
    let variable = {
      type: val,
    };
    dispatch(
      dynamicRequest(
        keys,
        get_migration_dashboard_district_wise_list_query,
        variable
      )
    );
  };

  const handleChange = (values) => {
    setDashboardItems(values);
  };

  const handleChangeDistrict = (values) => {
    set_district_ids(values);
  };

  const handle_change_dates = (type, event) => {
    if (type === "from_date") {
      set_from_date(event);
    } else {
      set_to_date(event);
    }
  };

  const handle_show_percentage_click = () => {
    set_show_percentage(true);
    get_migration_dashboard_district_wise_list("percentage");
  };

  const handle_district_count_click = () => {
    set_show_percentage(false);
    get_migration_dashboard_district_wise_list();
  };

  const handleRedirect = (value) => {
    const queryParams = new URLSearchParams();
    queryParams.set("status", value);
    queryParams.set("district_id", district_ids);
    queryParams.set("choose_active_key", "migration");

    // if (type != "velocity") {
    history.push({
      pathname: "/migration-society-list",
      search: `?${queryParams.toString()}`,
    });
    // }
  };

  const handleRedirectCount = (value, district_id) => {
    const queryParams = new URLSearchParams();
    queryParams.set("status", value);
    queryParams.set("district_id", district_id || "");
    queryParams.set("choose_active_key", "migration");

    // if (type != "velocity") {
    history.push({
      pathname: "/migration-society-list",
      search: `?${queryParams.toString()}`,
    });
    // }
  };

  const handle_manual_check_click = (label, value) => {
    const queryParams = new URLSearchParams();

    if (label === "Reverted") {
      queryParams.set("status", "reverted");
    } else {
      queryParams.set("status", "uploaded_with_od_error");
    }
    queryParams.set("district_id", district_ids);
    queryParams.set("value", value);
    queryParams.set("choose_active_key", "migration");

    // if (type != "velocity") {
    history.push({
      pathname: "/migration-society-list",
      search: `?${queryParams.toString()}`,
    });
    // }
  };
  const dashboards = [
    {
      name: "Total Societies",
      value: null,
      icon: NoOfSocieties,
      color: "#316CA6",
      sub_color: "#316CA6",
      count: data?.total_society,
    },
    {
      name: "Live",
      value: "live",
      icon: Live,
      color: "#28A745",
      sub_color: "#28A745",
      count: data?.accounting_date_live_society_count,
    },
    {
      name: "Backlog Entry",
      value: "back_log_entry",
      icon: precessing,
      color: "#706BB0",
      sub_color: "#706BB0",
      count: data?.live_society_count,
      backlog_percentage: data?.backlog_percentage,
    },
    {
      name: "Test Credentials Verified",
      value: "test_credential_verified",
      icon: Credential,
      color: "#6C756A",
      sub_color: "#6C756A",
      count: data?.test_verified_status,
      avg_day_to_test_verify: data?.avg_day_to_test_verify,
    },
    {
      name: "Test Credentials Given",
      value: "test_credentials_given",
      icon: Credential,
      color: "#6C757D",
      sub_color: "#6C757D",
      count: data?.testing_society_count,
      average_day_to_verify: data?.avg_day_to_verify,
    },
    {
      name: "Validated",
      value: "validated",
      icon: Validated2,
      color: "#4292C0",
      sub_color: "#4292C0",
      count: data?.uploaded_without_error_count,
      avg_days_to_give_test_credential: data?.avg_days_to_give_test_credential,
    },
    {
      name: "Uploaded With Error",
      value: "uploaded_with_error",
      icon: UploadIcon,
      color: "#E67C21",
      sub_color: "#E67C21",
      count: data?.uploaded_with_error_count,
      Manual_failure: data?.uploaded_with_od_error_count,
      revert_error_count: data?.requested_to_revert_count,
    },
    {
      name: "Not Started",
      value: "not_started",
      icon: NotStarted,
      color: "#D53238",
      sub_color: "#D53238",
      count: data?.not_started_process,
    },
  ];

  const conditionalRender = (value, record, status) => {
    return (
      <Text
        style={{
          cursor: value > 0 ? "pointer" : "",
          color: value > 0 ? "#007bff" : "black",
        }}
        onClick={() =>
          value > 0 ? handleRedirectCount(status, record?.id) : null
        }
      >
        {show_percentage ? (value !== 0 ? formatFloat(value) : value) : value}
      </Text>
    );
  };

  const conditionalRenderAging = (value, record, status) => {
    const formatValue = (val) => Math.round(val);
    return (
      <Text
        style={{
          cursor: status != "validated" ? (value > 0 ? "pointer" : "") : "auto",
          color:
            status != "validated" ? (value > 0 ? "#007bff" : "black") : "black",
        }}
        onClick={() => {
          if (status === "validated") {
            return;
          } else {
            value > 0 ? handleRedirectCount(status, record?.id) : null;
          }
        }}
      >
        {formatValue(value)}
      </Text>
    );
  };

  let formatFloat = (value) => {
    if (value === null || value === undefined) return value;
    const roundedValue = Math.round(parseFloat(value) * 10) / 10;
    return roundedValue.toFixed(1);
  };

  const columns = [
    {
      title: t("table:s_no"),
      dataIndex: "s_no",
      key: "s_no",
      align: "left",
      width: 65,
      render: (_, __, index) => <span>{index + 1}</span>,
    },
    {
      title: t("table:district_name"),
      dataIndex: "district_name",
      key: "district_name",
      align: "left",
      width: 100,
      sorter: (a, b) => a.district_name.localeCompare(b.district_name),
    },
    {
      title: `${t("table:total_society_count")} ${show_percentage ? "%" : ""}`,
      dataIndex: "total_society_count",
      key: "total_society_count",
      align: "right",
      width: 80,
      flex: 1,
      sorter: (a, b) => a.total_society_count - b.total_society_count,
      render: (value, record) => conditionalRender(value, record, null),
    },
    {
      title: `${t("table:not_started")} ${show_percentage ? "%" : ""}`,
      dataIndex: "not_started_process",
      key: "not_started_process",
      align: "right",
      width: 80,
      flex: 1,
      sorter: (a, b) => a.not_started_process - b.not_started_process,
      render: (value, record) =>
        conditionalRender(value, record, "not_started"),
    },
    {
      title: `${t("table:with_error")} ${show_percentage ? "%" : ""}`,
      dataIndex: "uploaded_with_error_count",
      key: "uploaded_with_error_count",
      align: "right",
      width: 130,
      flex: 1,
      sorter: (a, b) =>
        a.uploaded_with_error_count - b.uploaded_with_error_count,
      render: (value, record) =>
        conditionalRender(value, record, "uploaded_with_error"),
    },
    {
      title: `${t("table:without_error")} ${show_percentage ? "%" : ""}`,
      dataIndex: "uploaded_without_error_count",
      key: "uploaded_without_error_count",
      align: "right",
      width: 85,
      flex: 1,
      sorter: (a, b) =>
        a.uploaded_without_error_count - b.uploaded_without_error_count,
      render: (value, record) => conditionalRender(value, record, "validated"),
    },
    is_show_aging && {
      title: t("table:aging_count"),
      dataIndex: "avg_days_to_give_test_credential",
      key: "avg_days_to_complete",
      align: "right",
      width: 85,
      flex: 1,
      sorter: (a, b) =>
        a.avg_days_to_give_test_credential - b.avg_days_to_give_test_credential,
      // render: (text) => Math.round(text),
      render: (value, record) =>
        conditionalRenderAging(value, record, "validated"),
    },
    {
      title: `${t("table:test_credentials_given")} ${
        show_percentage ? "%" : ""
      }`,
      dataIndex: "testing_society_count",
      key: "testing_society_count",
      align: "right",
      width: 100,
      flex: 1,
      sorter: (a, b) => a.testing_society_count - b.testing_society_count,
      render: (value, record) =>
        conditionalRender(value, record, "test_credentials_given"),
    },
    {
      title: `${t("table:test_verified_status")} ${show_percentage ? "%" : ""}`,
      dataIndex: "test_verified_status",
      key: "test_verified_status",
      align: "right",
      width: 100,
      sorter: (a, b) => a.test_verified_status - b.test_verified_status,
      render: (value, record) =>
        conditionalRender(value, record, "test_credential_verified"),
    },
    is_show_aging && {
      title: t("table:avg_day_to_verify"),
      dataIndex: "avg_day_to_verify",
      key: "avg_day_to_verify",
      align: "right",
      width: 85,
      flex: 1,
      sorter: (a, b) => a.avg_day_to_verify - b.avg_day_to_verify,
      // render: (text) => Math.round(text),
      render: (value, record) =>
        conditionalRenderAging(value, record, "test_credentials_given"),
    },
    {
      title: `${t("table:back_log_entry")} ${show_percentage ? "%" : ""}`,
      dataIndex: "live_society_count",
      key: "live_society_count",
      align: "right",
      width: 95,
      flex: 1,
      sorter: (a, b) => a.live_society_count - b.live_society_count,
      render: (value, record) =>
        conditionalRender(value, record, "back_log_entry"),
    },
    {
      title: `${t("table:live")} ${show_percentage ? "%" : ""}`,
      dataIndex: "accounting_date_live_society_count",
      key: "accounting_date_live_society_count",
      align: "right",
      width: 50,
      flex: 1,
      sorter: (a, b) =>
        a.accounting_date_live_society_count -
        b.accounting_date_live_society_count,
      render: (value, record) => conditionalRender(value, record, "live"),
    },
  ].filter(Boolean);

  const handle_on_change = (e) => {
    set_is_show_aging(e.target.checked);
  };

  // const tableWidth = useBreakpointValue({
  //   base: "600px",
  //   md: "1000px",
  //   lg: "1000px",
  //   xl: "1300px",
  // });

  const handle_download_excel_click = () => {
    handle_excel_download(
      district_wise_count_list,
      show_percentage,
      is_show_aging
    );
  };

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      width="100%"
    >
      <Box
        style={{
          width: "91%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        {JSON.parse(roles)?.[0]?.name === "officer" && (
          <HStack
            width="475px"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent:
                type === "velocity" ? "space-between" : "flex-end",
            }}
          >
            {type === "velocity" && (
              <>
                <VForm.Date
                  placeholder={"Select From Date"}
                  // label={t("from_date")}
                  // field="from_date"
                  value={from_date}
                  onChange={(e) => handle_change_dates("from_date", e)}
                  rules={[
                    {
                      required: true,
                      message: t("Start date is required"),
                    },
                  ]}
                  disabledDate={(current) => {
                    return current && current > new Date();
                  }}
                  // defaultValue={from_date}
                />
                <VForm.Date
                  // label={t("to_date")}
                  placeholder={"Select To Date"}
                  value={to_date}
                  field="to_date"
                  onChange={(e) => handle_change_dates("to_date", e)}
                  rules={[
                    {
                      required: true,
                      message: t("To date is required"),
                    },
                  ]}
                  disabledDate={(current) => {
                    return current && current > moment();
                  }}
                  // defaultPickerValue={to_date}
                  defaultValue={to_date}
                />
              </>
            )}

            <VForm.Select
              placeholder={"Select District  "}
              isSearchable
              allowClear
              // label={t("District")}
              field="district_id"
              options={districts}
              onChange={handleChangeDistrict}
              loading={district_loading}
              className={"district-dropdown"}
            />
          </HStack>
        )}
      </Box>
      {(role === "Admin" || role === "rcs" || role === "mdccb") && (
        <Box width="400px" style={{ marginBottom: "10px" }}>
          <VForm.Select
            isSearchable
            allowClear
            label={t("District")}
            field="district_id"
            options={get_districts}
            onChange={handleChange}
          />
        </Box>
      )}

      {data?.loading ? (
        <DataMigrationDashboardLoading />
      ) : (
        <SimpleGrid
          columns={{
            base: 1,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
            "2xl": 5,
          }}
          spacingY="27px"
          spacingX="28px"
          marginBottom="30px"
          // width="100%"
        >
          {dashboards.map((dashboard) => (
            <DashboardCard
              dashboard_obj={dashboard}
              key={dashboard.name}
              handleRedirect={handleRedirect}
              icon={dashboard.icon}
              value={dashboard.count}
              dashBoardValue={dashboard.value}
              name={dashboard.name}
              color={dashboard.sub_color}
              amount_format={false}
              handle_manual_check_click={handle_manual_check_click}
              Manual_failure={
                dashboard.name === "Uploaded With Error"
                  ? dashboard.Manual_failure
                  : null
              }
              average_day_to_verify={dashboard?.average_day_to_verify}
              avg_days_to_give_test_credential={
                dashboard?.avg_days_to_give_test_credential
              }
              avg_day_to_test_verify={dashboard?.avg_day_to_test_verify}
              backlog_percentage={dashboard?.backlog_percentage}
              Manual_success={dashboard.name === "Validated"}
              revert_error_count={dashboard.revert_error_count}
              type={type}
            />
          ))}
        </SimpleGrid>
      )}

      {JSON.parse(roles)?.[0]?.name === "officer" && type != "velocity" && (
        <>
          <Box variant={"migration_dashboard"}>
            <Checkbox onChange={handle_on_change}>
              <Text>{t("show_aging")}</Text>
            </Checkbox>
            {show_percentage ? (
              <Button
                onPress={handle_district_count_click}
                colorScheme="primary"
                variant="outline"
                style={{
                  marginLeft: "10px",
                  height: "33px",
                  width: "130px",
                  marginRight: "10px",
                }}
              >
                {t("show_count")}
              </Button>
            ) : (
              <Button
                onPress={handle_show_percentage_click}
                colorScheme="primary"
                variant="outline"
                style={{
                  marginLeft: "10px",
                  height: "33px",
                  width: "130px",
                  marginRight: "10px",
                }}
              >
                {t("show_percentage")}
              </Button>
            )}

            <Button
              onPress={handle_download_excel_click}
              colorScheme="primary"
              variant="outline"
              style={{
                marginLeft: "10px",
                height: "33px",
                width: "130px",
                marginRight: "10px",
              }}
            >
              {t("download_excel")}
            </Button>
          </Box>

          <Box style={{ width: "100%" }}>
            <Table
              className="migration-table"
              loading={district_wise_count_list_loading}
              columns={columns}
              dataSource={district_wise_count_list}
              scroll={{ y: "calc(100vh - 240px)" }}
              pagination={false}
              rowKey="s_no"
              // style={{ width: tableWidth }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default MigrationDashboard;
