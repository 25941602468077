import { Box, Pressable, Text, VStack } from "native-base";
import React from "react";
import { countFormat } from "@helpers/utils";
import { useTranslation } from "react-i18next";

const KeyValueDisplay = ({ label, value, name }) => (
  <Box flexDirection="row" alignItems="flex-start">
    <Text fontSize="sm" color="white">
      {label}
    </Text>
    <Text fontSize="sm" color="white" ml={2} paddingRight={"15px"}>
      {name != "Uploaded With Error" ? value?.toFixed(2) : value}
    </Text>
  </Box>
);

const StatSection = ({ name, stats }) => (
  <Box flexDirection="row" alignItems="flex-start">
    {stats.map(({ label, value }, index) => (
      <KeyValueDisplay key={index} label={label} value={value} name={name} />
    ))}
  </Box>
);

const DashboardCard = (props) => {
  const { t } = useTranslation();
  const {
    icon,
    handleRedirect,
    name,
    value,
    color,
    amount_format,
    dashBoardValue,
    Manual_failure,
    Manual_success,
    revert_error_count,
    avg_days_to_give_test_credential,
    average_day_to_verify,
    avg_day_to_test_verify,
    backlog_percentage,
  } = props;

  const manual_error_stats = [
    { label: t("Manual_failure"), value: Manual_failure },
    { label: t("Reverted"), value: revert_error_count },
  ];

  const test_credentials_stats = [
    { label: t("average_day_to_verify"), value: average_day_to_verify },
  ];

  const validated_stats = [
    {
      label: t("avg_days_to_give_test_credential"),
      value: avg_days_to_give_test_credential,
    },
  ];

  const verified_stats = [
    { label: t("avg_day_to_test_verify"), value: avg_day_to_test_verify },
  ];

  const backlog_stats = [
    { label: t("backlog_percentage"), value: backlog_percentage },
  ];

  return (
    <Box>
      <Box
        minWidth="22vw"
        maxWidth="28vw"
        height="168px"
        shadow="5"
        backgroundColor={color}
        color="white"
        borderRadius="5px"
      >
        <Pressable
          onPress={() => value != "0" && handleRedirect(dashBoardValue)}
          style={{ cursor: value === 0 ? "" : "pointer" }}
        >
          <VStack p={6}>
            <Box>
              <img src={icon} width={"40px"} alt={name} />
            </Box>
            <Text fontSize="3xl" color="white" bold textAlign={"right"}>
              {name === "Pending Transactions" || name === "Number of Societies"
                ? countFormat(Number(value || 0))
                : `${amount_format === false ? "" : "₹"}${countFormat(
                    Number(Math?.round(value) || 0)
                  )}`}
            </Text>

            <Text fontSize="lg" color="white" textAlign={"left"}>
              {name}
            </Text>

            {name === "Uploaded With Error" && (
              <StatSection name={name} stats={manual_error_stats} />
            )}
            {name === "Test Credentials Given" && (
              <StatSection name={name} stats={test_credentials_stats} />
            )}
            {name === "Validated" && (
              <StatSection name={name} stats={validated_stats} />
            )}
            {name === "Test Credentials Verified" && (
              <StatSection name={name} stats={verified_stats} />
            )}
            {name === "Backlog Entry" && (
              <StatSection name={name} stats={backlog_stats} />
            )}
          </VStack>
        </Pressable>
      </Box>
    </Box>
  );
};

export default DashboardCard;
